import React from "react";
import { Routes, Route } from "react-router-dom";
import DashBoardDueno from "../layouts/DashBoardDueno.js";
import ListaEmpleado from "../admin-empleados/adminEmpleadosDueno.js";
import ListaAdministrador from "../admin-empleados/adminAdministradorDueno.js";
import VentasEmpleados from "../ventas/ventasEmpleados.js";
import RegistrosVentas from "../ventas/registroVentas.js";
import ConfiguracionesDueno from "../configuraciones/configuraciones.js";
import VerVenta from "../ventas/verVenta.js";
import CrearVentaDueno from "../ventas/crearVentaDueno.js";

function RutasDueno() {
  return (
    <Routes>
      <Route path="/" element={<DashBoardDueno />}>
        <Route path="/administrador" element={<ListaAdministrador />} />
        <Route path="/empleados" element={<ListaEmpleado />} />
        <Route path="/ventas-empleados" element={<RegistrosVentas />} />
        <Route path="/ventas-empleados/:id" element={<VerVenta />} />
        <Route path="/ventas-empleados-crear-venta/:dia/:mes/:anio/:local_id" element={<CrearVentaDueno />} />
        <Route path="/configuraciones" element={<ConfiguracionesDueno />} />
      </Route>
    </Routes>
  );
}

export default RutasDueno;
