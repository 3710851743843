import { useEffect, useState } from "react";
import Carga from "../../../componentes/cargando/Cargando.js";
import { Form, Button, Modal } from "react-bootstrap";
import React from "react";
import { formatearAPesos } from "../../../helpers/formatos.js";
import { buscarMetodopago } from "../../../service/ventas/cargarMetodoPago.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import { agregarVentaDueno } from "../../../service/ventas/generaVenta.js";
import {
  mensajeExitoCargando,
  mesanjeExitoPersonalizado,
} from "../../../helpers/mensajesSwalExito.js";
import Swal from "sweetalert2";
import CargandoComponente from "../../../componentes/cargando/CargandoComponentes.js";
import {
  buscarAdministradoresPorLocalService,
  buscarEmpleadosPorLocalService,
} from "../../../service/dueno/buscarEmpleados.js";
import { useParams } from "react-router-dom";
import { mensajeErrorPersonalizado } from "../../../helpers/mensajesSwalError.js";

const CrearVentaDueno = () => {
  const [cargarPagina, setCargarPagina] = useState(true);
  const navigate = useNavigate();
  const { verificacionToken } = useAuth();
  const [cargandoEmpleados, setCargandoEmpleados] = useState(true);
  const [cargandoMetodo, setCargandoMetodo] = useState(true);
  const [cargandoAdministradores, setCargandoAdministradores] = useState(true);
  const [idAdministrador, setIdAdministrador] = useState(0);

  const { dia, mes, anio, local_id } = useParams();

  //montos totales
  const [montoTotal, setMontoTotal] = useState({
    totalEmpleados: 0,
    totalMetodoPago: 0,
  });

  //empleados
  const [empleados, setEmpleados] = useState([]);
  const [administradores, setAdministradores] = useState([]);

  //metodos de pago
  const [metodosPagos, setMetodosPagos] = useState([]);

  //valores
  const [valores, setValores] = useState([]);
  const [valoresMetodoPago, setValoresMetodoPago] = useState([]);

  //Descuentos
  const [descuentos, setDescuentos] = useState(0);
  const [comentarios, setComentarios] = useState("");

  //detalles
  const [mostrarDetalles, setMostrarDetalles] = useState(false);
  const [nombreDetalles, setNombreDetalles] = useState("");
  const [servicioDetalles, setServicioDetalles] = useState([]);

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    obtenerEmpleados(local_id);
    obtenerAdministradores(local_id);
    obtenerMetodosPago();
    setCargarPagina(false);
  }, []);

  const obtenerEmpleados = async (id) => {
    const respuesta = await buscarEmpleadosPorLocalService(id);
    if (respuesta[0]) {
      setEmpleados(respuesta[1]);
      if (respuesta[1].length > 0) {
        console.log(respuesta[1]);
        setValores(
          respuesta[1].map((emp) => {
            if (emp.servicios.length === 0) {
              return {
                id: emp.id,
                valor: "",
                cantidadCliente: "",
              };
            } else {
              return {
                id: emp.id,
                valor: "",
                cantidadCliente: "",
                servicios: emp.servicios.map((ser) => {
                  return {
                    id: ser.id,
                    idUsuario: emp.id,
                    servicio: ser.servicio,
                    valor: "",
                  };
                }),
              };
            }
          })
        );
      }
    } else {
      if (respuesta[1] === 400) {
        setEmpleados([]);
      } else if (respuesta[1] === 401) {
        navigate("/expiro-sesion");
      } else if (respuesta[1] === 403) {
        navigate("/no-autorizado");
      } else {
        navigate("/no-encontrado");
      }
    }
    setCargandoEmpleados(false);
  };

  const obtenerAdministradores = async (id) => {
    const respuesta = await buscarAdministradoresPorLocalService(id);
    if (respuesta[0]) {
      setAdministradores(respuesta[1]);
      setIdAdministrador(respuesta[1][0].id);
    } else {
      if (respuesta[1] === 400) {
        setAdministradores([]);
      } else if (respuesta[1] === 401) {
        navigate("/expiro-sesion");
      } else if (respuesta[1] === 403) {
        navigate("/no-autorizado");
      } else {
        navigate("/no-encontrado");
      }
    }
    setCargandoAdministradores(false);
  };

  const obtenerMetodosPago = async () => {
    const respuesta = await buscarMetodopago();
    if (respuesta[0]) {
      setMetodosPagos(respuesta[1]);
      setValoresMetodoPago(
        respuesta[1].map((met) => ({ id: met.id, valor: "" }))
      );
    } else {
      if (respuesta[1] === 400) {
        setMetodosPagos([]);
      } else if (respuesta[1] === 401) {
        navigate("/expiro-sesion");
      } else if (respuesta[1] === 403) {
        navigate("/no-autorizado");
      } else {
        navigate("/no-encontrado");
      }
    }
    setCargandoMetodo(false);
  };

  const actualizarCantidadClientes = (id, event) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;

    setValores((prevValores) => {
      const nuevosValores = prevValores.map((item) =>
        item.id === id ? { ...item, cantidadCliente: valorNumerico } : item
      );
      return nuevosValores;
    });
  };

  const obtenerDescuento = (event) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;
    setDescuentos(valorNumerico);
  };

  const actualizarValor = (id, event, tipo) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;

    const setValoresFunc =
      tipo === "empleado" ? setValores : setValoresMetodoPago;

    setValoresFunc((prevValores) => {
      const nuevosValores = prevValores.map((item) =>
        item.id === id ? { ...item, valor: valorNumerico } : item
      );

      if (tipo === "empleado") {
        calcularTotalEmpleados(nuevosValores);
        calcularTotalMetodoPago(valoresMetodoPago);
      } else {
        calcularTotalMetodoPago(nuevosValores);
        calcularTotalEmpleados(valores);
      }

      return nuevosValores;
    });
  };

  const actualizarValorDetalles = (id, event, idUsuario) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;
    setValores((prevValores) => {
      const nuevosValores = prevValores.map((item) => {
        if (item.servicios !== undefined && item.id === idUsuario) {
          const nuevosServicios = item.servicios.map((ser) =>
            ser.id === id ? { ...ser, valor: valorNumerico } : ser
          );
          const sumaServicios = nuevosServicios.reduce(
            (acumulador, ser) => acumulador + Number(ser.valor || 0),
            0
          );

          return { ...item, servicios: nuevosServicios, valor: sumaServicios };
        }
        return item;
      });
      calcularTotalEmpleadosServicio(nuevosValores);
      return nuevosValores;
    });
  };

  const calcularTotalEmpleadosServicio = (nuevosValores) => {
    const totalEmpleados = nuevosValores.reduce((total, item) => {
      const valor = item.valor ? Number(item.valor) : 0;
      return total + valor;
    }, 0);

    setMontoTotal((prevTotal) => ({
      ...prevTotal,
      totalEmpleados: totalEmpleados,
    }));
  };

  const calcularTotalEmpleados = (nuevosValores) => {
    const totalEmpleados = nuevosValores.reduce(
      (total, item) => total + (item.valor || 0),
      0
    );
    setMontoTotal((prevTotal) => ({
      ...prevTotal,
      totalEmpleados: totalEmpleados,
    }));
  };

  const calcularTotalMetodoPago = (nuevosValores) => {
    const totalMetodoPago = nuevosValores.reduce(
      (total, item) => total + (item.valor || 0),
      0
    );
    setMontoTotal((prevTotal) => ({
      ...prevTotal,
      totalMetodoPago: totalMetodoPago,
    }));
  };

  const obtenerVenta = (e) => {
    e.preventDefault();
    if (descuentos === "" || descuentos === undefined) {
      setDescuentos(0);
    }
    const data = {
      empleados: valores,
      metodosPagos: valoresMetodoPago,
      descuentosDetalles: {
        descuentos,
        comentarios,
      },
      totalVenta: montoTotal.totalEmpleados - descuentos,
      fecha: `${anio}/${mes}/${dia}`,
      administrador_id: idAdministrador,
      local_id,
    };
    Swal.fire({
      title: "Estas seguro?",
      text: "Generar Venta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Generar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        mensajeExitoCargando();
        await addVenta(data);
      }
    });
  };

  const addVenta = async (data) => {
    const respuestaVenta = await agregarVentaDueno(data);
    if (respuestaVenta[0]) {
      mesanjeExitoPersonalizado(respuestaVenta[2]);
      navigate("/dueno/ventas-empleados");
    } else {
      if (respuestaVenta[1] === 400) {
        mensajeErrorPersonalizado(respuestaVenta[2]);
      } else if (respuestaVenta[1] === 401) {
        mensajeErrorPersonalizado(respuestaVenta[2]);
        navigate("/expiro-sesion");
      } else if (respuestaVenta[1] === 403) {
        mensajeErrorPersonalizado(respuestaVenta[2]);
        navigate("/no-autorizado");
      } else {
        mensajeErrorPersonalizado(respuestaVenta[2]);
        navigate("/no-encontrado");
      }
    }
  };

  const handlerIdAdmin = (id) => {
    setIdAdministrador(id);
  };

  const mostrarDetallesEmpleado = (servicios, nombre, apellido) => {
    setServicioDetalles(servicios);
    setMostrarDetalles(true);
    setNombreDetalles(`${nombre} ${apellido}`);
  };

  return (
    <div>
      {cargarPagina ? (
        <Carga />
      ) : (
        <div>
          <div>
            {cargandoEmpleados ? (
              <Carga />
            ) : (
              <div>
                {empleados.length === 0 ? (
                  <div className="alert alert-danger" role="alert">
                    No tienes empleados a tu cargo...
                  </div>
                ) : (
                  <div className="container mt-4">
                    <Form className="py-3" onSubmit={obtenerVenta}>
                      <div className="row border border-success rounded-4 my-2 py-2">
                        <div className="col-12">
                          <h2 className="text-center">
                            Seleccione el Administrador
                          </h2>
                        </div>
                        {cargandoAdministradores ? (
                          <CargandoComponente />
                        ) : (
                          <div className="col-12">
                            <select
                              className="form-select mt-3"
                              onChange={(e) => handlerIdAdmin(e.target.value)}
                            >
                              {administradores.map((adm) => (
                                <option key={adm.id} value={adm.id}>
                                  {adm.perfil.nombres} {adm.perfil.apellidos}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="row border border-success rounded-4 my-2 py-2">
                        <h2>Ingresar Ventas Diarias Empleados</h2>
                        {empleados.map((emp, llave) => (
                          <div key={llave} className="py-3 mb-3">
                            <div className="row align-items-center gap-2">
                              <div className="col-md-2">
                                <label className="fs-5 fw-bold">
                                  {emp.perfil.nombres} {emp.perfil.apellidos}:
                                </label>
                              </div>
                              {emp.servicios.length === 0 ? (
                                <></>
                              ) : (
                                <>
                                  <div className="col-md-2">
                                    <label className="fs-5 fw-bold">
                                      {valores.find(
                                        (v) =>
                                          v.id === emp.id &&
                                          v.valor !== undefined &&
                                          v.valor !== ""
                                      )
                                        ? formatearAPesos(
                                            valores.find((v) => v.id === emp.id)
                                              .valor
                                          )
                                        : "$0"}
                                    </label>
                                  </div>
                                </>
                              )}
                              {emp.servicios.length === 0 ? (
                                <div className="col-md-5">
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    No puedes ingresar cantidad clientes
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-3">
                                  <Form.Control
                                    type="text"
                                    name={emp.id}
                                    placeholder="Cantidad Cliente"
                                    className="w-100"
                                    value={
                                      valores.find((v) => v.id === emp.id)
                                        ?.cantidadCliente || ""
                                    }
                                    onChange={(event) =>
                                      actualizarCantidadClientes(emp.id, event)
                                    }
                                  />
                                </div>
                              )}
                              <div className="col-md-4">
                                {emp.servicios.length === 0 ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    No tiene servicios asignado.
                                  </div>
                                ) : (
                                  <Button
                                    variant="btn btn-outline-warning"
                                    type="button"
                                    onClick={() =>
                                      mostrarDetallesEmpleado(
                                        emp.servicios,
                                        emp.perfil.nombres,
                                        emp.perfil.apellidos
                                      )
                                    }
                                  >
                                    Detalles
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="row border border-success rounded-4 my-2 py-2">
                        <h2>Ingresar Ventas Métodos de Pagos</h2>
                        <div>
                          {cargandoMetodo ? (
                            <CargandoComponente />
                          ) : (
                            <div>
                              {metodosPagos.map((met, llave) => (
                                <div key={llave} className="py-3 col-12 mb-3">
                                  <div className="row align-items-center">
                                    <div className="col-md-3">
                                      <label className="fs-5 fw-bold">
                                        {met.metodoPago}
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <Form.Control
                                        type="text"
                                        name={met.id}
                                        placeholder="Monto total"
                                        className="w-100"
                                        value={formatearAPesos(
                                          valoresMetodoPago.find(
                                            (v) => v.id === met.id
                                          )?.valor || ""
                                        )}
                                        onChange={(event) =>
                                          actualizarValor(
                                            met.id,
                                            event,
                                            "metodo"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {servicioDetalles.length === 0 ? (
                            <div></div>
                          ) : (
                            <Modal
                              show={mostrarDetalles}
                              onHide={() => setMostrarDetalles(false)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  Detalles de Servicio {nombreDetalles}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form>
                                  <div className="row align-items-center">
                                    {servicioDetalles.map((ser) => (
                                      <React.Fragment key={ser.id}>
                                        <div className="col-md-4 py-1">
                                          <label className="fs-5 fw-bold">
                                            {ser.servicio}
                                          </label>
                                        </div>
                                        <div className="col-md-6 py-1">
                                          <Form.Control
                                            type="text"
                                            name={ser.id}
                                            value={formatearAPesos(
                                              valores
                                                .find(
                                                  (v) =>
                                                    v.id ===
                                                      ser.usuarios_servicios
                                                        .usuarioId &&
                                                    v.servicios &&
                                                    v.servicios.find(
                                                      (s) => s.id === ser.id
                                                    )
                                                )
                                                ?.servicios.find(
                                                  (s) => s.id === ser.id
                                                )?.valor || ""
                                            )}
                                            placeholder="Monto total"
                                            onChange={(event) =>
                                              actualizarValorDetalles(
                                                ser.id,
                                                event,
                                                ser.usuarios_servicios.usuarioId
                                              )
                                            }
                                          />
                                        </div>
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </Form>
                              </Modal.Body>
                              <Button
                                className="m-auto mb-1"
                                variant="outline-success"
                                onClick={() => setMostrarDetalles(false)}
                              >
                                Cerrar
                              </Button>
                            </Modal>
                          )}
                        </div>
                      </div>
                      <div className="row border border-success rounded-4 my-2 py-2">
                        <h2>Ingresar Descuentos</h2>
                        <div className="py-3 col-12 mb-3 gap-3">
                          <div className="row align-items-center">
                            <div className="col-md-3">
                              <label className="fs-5 fw-bold">Descuentos</label>
                            </div>
                            <div className="col-md-9">
                              <Form.Control
                                type="text"
                                name="descuento"
                                placeholder="Ingrese el descuento"
                                className="w-100"
                                value={formatearAPesos(descuentos)}
                                onChange={(event) => obtenerDescuento(event)}
                              />
                            </div>
                            <div className="col-md-3">
                              <label className="fs-5 fw-bold">
                                Comentarios
                              </label>
                            </div>
                            <div className="col-md-9 my-4">
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="comentarios"
                                placeholder="Ingrese un comentario si exite un descuento"
                                className="w-100"
                                value={comentarios}
                                required={descuentos === 0 ? false : true}
                                onChange={(e) => setComentarios(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row border border-success rounded-4 my-4 p-3">
                        <h2 className="mb-4 text-center">
                          Detalles de las Ventas
                        </h2>

                        <div className="text-center col-md-3">
                          <h6
                            className={
                              montoTotal.totalEmpleados ===
                              montoTotal.totalMetodoPago
                                ? "text-success fw-bold"
                                : "text-danger fw-bold"
                            }
                          >
                            Ventas Empleados:{" "}
                            {montoTotal.totalEmpleados === 0
                              ? "$0"
                              : formatearAPesos(montoTotal.totalEmpleados)}
                          </h6>
                        </div>

                        <div className="text-center col-md-3">
                          <h6
                            className={
                              montoTotal.totalEmpleados ===
                              montoTotal.totalMetodoPago
                                ? "text-success fw-bold"
                                : "text-danger fw-bold"
                            }
                          >
                            Ventas Métodos de Pagos:{" "}
                            {montoTotal.totalMetodoPago === 0
                              ? "$0"
                              : formatearAPesos(montoTotal.totalMetodoPago)}
                          </h6>
                        </div>

                        <div className="text-center col-md-3">
                          <h6
                            className={
                              descuentos === 0
                                ? "text-success fw-bold"
                                : "text-danger fw-bold"
                            }
                          >
                            Descuentos:{" "}
                            {descuentos === 0
                              ? "$0"
                              : formatearAPesos(descuentos)}
                          </h6>
                        </div>

                        <div className="text-center col-md-3">
                          <h6
                            className={
                              montoTotal.totalEmpleados ===
                              montoTotal.totalMetodoPago
                                ? "text-success fw-bold"
                                : "text-danger fw-bold"
                            }
                          >
                            Venta Total:{" "}
                            {montoTotal.totalEmpleados === 0 &&
                            montoTotal.totalMetodoPago === 0
                              ? "$0"
                              : formatearAPesos(
                                  montoTotal.totalEmpleados - descuentos
                                )}
                          </h6>
                          {montoTotal.totalEmpleados !==
                            montoTotal.totalMetodoPago && (
                            <p className="text-danger fw-bold">
                              Diferencia:{" "}
                              {formatearAPesos(
                                Math.abs(
                                  montoTotal.totalEmpleados -
                                    montoTotal.totalMetodoPago
                                )
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="text-center">
                        <Button
                          variant="btn btn-outline-success"
                          type="submit"
                          className="mt-2"
                          disabled={
                            montoTotal.totalEmpleados ===
                            montoTotal.totalMetodoPago
                              ? false
                              : true
                          }
                        >
                          Generar Venta
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CrearVentaDueno;
