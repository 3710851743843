import axios from "axios";

const obtenerRespuestaSesion = async (token, usuario_id, rol) => {
  const datos = { token, usuario_id };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/validar-sesion`,
      datos
    );

    return response;
  } catch (error) {
    return error;
  }
};

const obtenerRespuestaSesionRol = async (rol) => {
  let token = localStorage.getItem("token");
  let usuario_id = localStorage.getItem("usuario_id");

  const datos = {
    token,
    usuario_id,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/validar-sesion-rol`,
      datos
    )
    if(response.data === rol){
      return [200, true];
    }else{
      return [401, false];
    }
  } catch (error) {
    if (error.status === 401) {
      return [401, false];
    } else if (error.status === 403) {
      return [403, false];
    } else {
      return [400, false];
    }
  }
};

export { obtenerRespuestaSesion, obtenerRespuestaSesionRol };
