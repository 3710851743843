import React from "react";
import { AuthProvider } from "../../../AuthContext.js";
import DashBoardEmpleado from "../layouts/DashBoardEmplado.js";

const AppEmpleado = () => {
  return (
    <AuthProvider>
      <div className="container">
        <DashBoardEmpleado />
      </div>
    </AuthProvider>
  );
};

export default AppEmpleado;
