import axios from "axios";

const agregarVenta = async (data, codigo) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    if (codigo === "hoy") {
      const respuesta = await axios.post(
        `${process.env.REACT_APP_API_URL}/administrador/empleados-venta`,
        data,
        config
      );
      return [true, respuesta.status, respuesta.data];
    } else {
      const respuesta = await axios.post(
        `${process.env.REACT_APP_API_URL}/administrador/empleados-venta-anterior`,
        data,
        config
      );
      return [true, respuesta.status, respuesta.data];
    }
  } catch (error) {
    return [false, error.status, error.response.data];
  }
};

const agregarVentaDueno = async (data) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    const respuesta = await axios.post(
      `${process.env.REACT_APP_API_URL}/dueno/agregar-venta-dueno`,
      data,
      config
    );
    return [true, respuesta.status, respuesta.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
};

export { agregarVenta, agregarVentaDueno };
