import axios from "axios";

const buscarVentaDiaAnteriorServicio = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };
    try {
      const respuestaVenta = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrador/buscar-venta-anterior`,
        config
      );
      return respuestaVenta.status
    } catch (error) {
      return error.status
    }
}

export{
    buscarVentaDiaAnteriorServicio
}