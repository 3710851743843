import { useEffect, useState } from "react";
import {
  buscarLocales,
  agregarLocales,
  actualizarLocales,
} from "../../../service/dueno/configuracionesServiceDueno";
import CargaMedia from "../../../componentes/cargando/CargandoMedio";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { useAuth } from "../../../AuthContext";
import { formatearAPesos } from "../../../helpers/formatos";

const ConfiguracionesLocales = () => {
  const [cargandoLocales, setCargandoLocales] = useState(true);
  const { verificacionToken } = useAuth();

  const [locales, setLocales] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [selectedLocalInfo, setSelectedLocalInfo] = useState([]);

  const [editingLocal, setEditingLocal] = useState(false);
  const [newLocal, setNewLocal] = useState({
    nombres: "",
    direccion: "",
    kpi: ""
  });

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    buscarLocalesAdmins();
  }, []);

  const buscarLocalesAdmins = async () => {
    const respuesta = await buscarLocales();
    if (respuesta[1] === 200) {
      setLocales(respuesta[0]);
    }
    setCargandoLocales(false);
  };

  const handleShowModal = () => {
    setEditingLocal(false);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setNewLocal({
      nombres: "",
      direccion: "",
      kpi: ""
    });
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLocal({
      ...newLocal,
      [name]: value,
    });
  };

  //agregar local
  const handleAddLocal = () => {
    const add = async () => {
      const respuesta = await agregarLocales(newLocal);
      if (respuesta[1] === 201) {
        buscarLocalesAdmins();
      }
    };
    add();
    setNewLocal({
      id: "",
      nombres: "",
      direccion: "",
      kpi: ""
    });
    setEditingLocal(false);
    handleCloseModal();
  };

  const handleEditLocal = (local) => {
    setNewLocal({
      id: local.id,
      nombres: local.nombres,
      direccion: local.direccion,
      kpi: local.kpi
    });
    setEditingLocal(true);
    setShowModal(true);
  };

  //editar local
  const handleUpdateLocal = () => {
    const update = async () => {
      const respuesta = await actualizarLocales(newLocal);
      if (respuesta[1] === 201) {
        buscarLocalesAdmins();
      }
    };
    update();
    setNewLocal({
      nombres: "",
      direccion: "",
      kpi: ""
    });
    setEditingLocal(false);
    handleCloseModal();
  };

  const handleShowModalInfo = (local) => {
    setSelectedLocalInfo(local);
    setShowModalInfo(true);
  };

  const handleCloseModalInfo = () => {
    setShowModalInfo(false);
    setSelectedLocalInfo([]);
  };

  return (
    <>
      <div className="container border border-primary p-3 rounded-5">
        {cargandoLocales ? (
          <CargaMedia />
        ) : (
          <div>
            <div className="row align-items-center">
              <div className="col-md-9">
                <h2 className="display-3 text-primary shadow-title">Locales</h2>
              </div>
              <div className="col-md-3 text-end">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleShowModal}
                >
                  Agregar Local
                </Button>
              </div>
            </div>
            {locales.length === 0 ? (
              <div class="alert alert-danger" role="alert">
                No se encontraron locales
              </div>
            ) : (
              <Table striped bordered hover responsive className="mt-3">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Dirección</th>
                    <th>KPI</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {locales.map((local) => (
                    <tr key={local.id}>
                      <td>{local.nombres}</td>
                      <td>{local.direccion}</td>
                      <td>{formatearAPesos(local.kpi)}</td>
                      <td>
                        <div className="container">
                          <div className="row gap-2">
                            <Button
                              className="col flex-auto"
                              variant="btn btn-outline-warning"
                              onClick={() => handleEditLocal(local)}
                            >
                              Editar
                            </Button>
                            <Button
                              className="col flex-auto"
                              variant="btn btn-outline-info"
                              onClick={() =>
                                handleShowModalInfo(local.usuarios)
                              }
                            >
                              Información
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {editingLocal ? "Editar Local" : "Agregar Local"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formNombre">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombres"
                      placeholder="Nombre del Local"
                      value={newLocal.nombres}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formDireccion" className="mt-3">
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control
                      type="text"
                      name="direccion"
                      value={newLocal.direccion}
                      onChange={handleInputChange}
                      placeholder="Direccion del Local"
                    />
                  </Form.Group>
                  <Form.Group controlId="formKpi" className="mt-3">
                    <Form.Label>KPI</Form.Label>
                    <Form.Control
                      type="text"
                      name="kpi"
                      value={newLocal.kpi}
                      onChange={handleInputChange}
                      placeholder="Establecer un Kpi"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="btn btn-outline-danger"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </Button>
                <Button
                  variant="btn btn-outline-primary"
                  onClick={editingLocal ? handleUpdateLocal : handleAddLocal}
                >
                  {editingLocal ? "Actualizar" : "Agregar"}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModalInfo} onHide={handleCloseModalInfo} size="lg">
              <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                {selectedLocalInfo.length === 0 ? (
                  <div class="alert alert-danger text-center" role="alert">
                    <p>No hay empleados para este local.</p>
                  </div>
                ) : (
                  <div>
                    <h4>Listado del Personal</h4>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Rut</th>
                          <th>Nombre</th>
                          <th>Número</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedLocalInfo.map((empleado) => (
                          <tr key={empleado.id}>
                            <td>{empleado.perfil.rut}</td>
                            <td>
                              {empleado.perfil.nombres}{" "}
                              {empleado.perfil.apellidos}
                            </td>
                            <td>{empleado.perfil.numero}</td>
                            <td
                              style={{
                                color: empleado.enabled ? "green" : "red",
                              }}
                            >
                              {empleado.enabled ? "Habilitado" : "Bloqueado"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="btn btn-outline-secondary"
                  onClick={handleCloseModalInfo}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default ConfiguracionesLocales;
