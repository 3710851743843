import React, { useState } from 'react';

const PuntoVentaPartner = () => {

  const [rutEmpleado, setRutEmpleado] = useState('');
  const [detalle, setDetalle] = useState('');
  const [producto, setProducto] = useState('');
  const [cantidad, setCantidad] = useState(1);
  const [precio, setPrecio] = useState('');
  const [metodoPago, setMetodoPago] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario, como enviar los datos a un servidor
    console.log({
      rutEmpleado,
      detalle,
      producto,
      cantidad,
      precio,
      metodoPago,
    });
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Formulario de Venta</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="rutEmpleado" className="form-label">
            Rut Empleado
          </label>
          <input
            type="text"
            className="form-control"
            id="rutEmpleado"
            placeholder="Ingrese su Rut"
            value={rutEmpleado}
            onChange={(e) => setRutEmpleado(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="detalle" className="form-label">
            Detalle
          </label>
          <input
            type="tel"
            className="form-control"
            id="detalle"
            placeholder="Ingrese el detalle de la venta"
            value={detalle}
            onChange={(e) => setDetalle(e.target.value)}
            required
          />
        </div>


        {/* Cantidad y Precio */}
        <div className="row g-3">
          <div className="col-md-6">
          <label htmlFor="metodoPago" className="form-label">
            Método de Pago
          </label>
          <select
            className="form-select"
            id="metodoPago"
            value={metodoPago}
            onChange={(e) => setMetodoPago(e.target.value)}
            required
          >
            <option value="" disabled>
              Seleccione un método de pago
            </option>
            <option value="efectivo">Efectivo</option>
            <option value="tarjeta">Tarjeta de Crédito/Débito</option>
            <option value="transferencia">Transferencia Bancaria</option>
          </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="precio" className="form-label">
              Precio Unitario
            </label>
            <input
              type="number"
              className="form-control"
              id="precio"
              placeholder="Ingrese el precio unitario"
              value={precio}
              onChange={(e) => setPrecio(e.target.value)}
              required
            />
          </div>
        </div>

        {/* Botón de Enviar */}
        <div className="text-center mt-4">
          <button type="submit" className="btn btn-primary">
            Realizar Venta
          </button>
        </div>
      </form>
    </div>
  );
};

export default PuntoVentaPartner;