import Swal from "sweetalert2";

const mensajeExitoCargando = () => {
    Swal.fire({
        title: "Cargando...",
        text: "Por favor espera",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
}

const mesanjeExito = () => {
    Swal.close()
    Swal.fire({
        icon: "success",
        title: "¡Operación completada!",
        text: "Se ha completado correctamente.",
      });
}

const mesanjeExitoPersonalizado = (texto) => {
  Swal.close()
  Swal.fire({
      icon: "success",
      title: "¡Operación completada!",
      text: texto,
    });
}

const mensajeExitoRedirect = () => {
  Swal.fire({
    title: "Exito",
    text: "Se ha cambiado con exito su contraseña",
    icon: "success",
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Volver"
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = '/';
    }
  });
}

export{
    mensajeExitoCargando,
    mesanjeExito,
    mesanjeExitoPersonalizado,
    mensajeExitoRedirect
}