import { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";

const obtenerMesActual = () => {
  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth() + 1;
  return mesActual;
};

const obtenerAnioActual = () => {
  return new Date().getFullYear();
};

const obtenerDiasDelMes = (m, a) => {
  return new Date(a, m, 0).getDate();
};

const generarListaDias = (mes, anio) => {
  const cantidadDias = obtenerDiasDelMes(mes, anio);
  return Array.from({ length: cantidadDias }, (_, i) => i + 1);
};

const obtenerDiaSemana = (dia) => {
  const fecha = new Date(new Date().getFullYear(), new Date().getMonth(), dia);
  const diasDeLaSemana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  return diasDeLaSemana[fecha.getDay()];
};

const VentaDiaria = () => {
  const { verificacionToken } = useAuth();

  const [cantidadDia, setCantidadDia] = useState(
    generarListaDias(obtenerMesActual(), obtenerAnioActual())
  );
  const [mes, setMes] = useState(obtenerMesActual());
  const [anio, setAnio] = useState(obtenerAnioActual());
  const [anion, setAnion] = useState([]);

  const [ cargando, setCargando ] = useState(true)

  useEffect(() => {
    verificacionToken("ROLE_ADMINISTRADOR");
  }, []);

  const filtrarMes = (e) => {
    e.preventDefault();
    setCargando(true);
    //buscarVentasPorMes(mes, anio);
    setCantidadDia(generarListaDias(mes, anio));
    setCargando(false);
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center">Mis Ventas Mesuales</h2>
      <div className="row mb-3 justify-content-center py-5">
        <form onSubmit={filtrarMes} className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="filtroMes" className="form-label">
              Seleccionar Mes
            </label>
            <select
              id="filtroMes"
              className="form-select"
              value={mes}
              onChange={(e) => setMes(e.target.value)}
            >
              <option value="1">Enero</option>
              <option value="2">Febrero</option>
              <option value="3">Marzo</option>
              <option value="4">Abril</option>
              <option value="5">Mayo</option>
              <option value="6">Junio</option>
              <option value="7">Julio</option>
              <option value="8">Agosto</option>
              <option value="9">Septiembre</option>
              <option value="10">Octubre</option>
              <option value="11">Noviembre</option>
              <option value="12">Diciembre</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="filtroAnio" className="form-label">
              Seleccionar Año
            </label>
            <select
              id="filtroAnio"
              className="form-select"
              value={anio}
              onChange={(e) => setAnio(e.target.value)}
            >
              {anion.map((a) => (
                <option key={a.id} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 d-flex align-items-end">
            <button type="submit" className="btn btn-outline-success me-2">
              Aplicar Filtros
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default VentaDiaria;
