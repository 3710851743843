import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  mensajeExitoCargando,
  mensajeExitoRedirect,
  mesanjeExitoPersonalizado,
} from "../../helpers/mensajesSwalExito";
import { mensajeErrorPersonalizado } from "../../helpers/mensajesSwalError";

const RecuperarPassword = () => {
  const { id, token } = useParams();
  const [valido, setValido] = useState(false);
  const [usuarioId, setUsuarioId] = useState("");
  const [usuarioToken, setUsuarioToken] = useState("");
  const [passwordRecuperar, setPasswordRecuperar] = useState("");
  const [confirmPasswordRecuperar, setConfirmPasswordRecuperar] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    validarSolicitud();
  }, []);

  const validarSolicitud = () => {
    const validarSolicitudToken = async () => {
      try {
        mensajeExitoCargando();
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/restablecer-password/${id}/${token}`
        );
        setUsuarioId(response.data.usuario_id);
        setUsuarioToken(response.data.token);
        setValido(true);
        mesanjeExitoPersonalizado("Se ha verificado correctamente");
      } catch (error) {
        if (error.status === 400) {
          mensajeErrorPersonalizado("Url inválida.");
        } else if (error.status === 401) {
          mensajeErrorPersonalizado(
            "El token ya expiro, debe enviar otra solicitud"
          );
        } else if (error.status === 403) {
          mensajeErrorPersonalizado("Error al validar el usuario");
        } else if (error.status === 404) {
          mensajeErrorPersonalizado("No se econtro el usuario");
        } else {
          mensajeErrorPersonalizado("Error en la solicitud");
        }
        setValido(false);
      }
    };
    validarSolicitudToken();
  };

  const cambiarPassword = () => {
    const solicitudCambiarPasswword = async () => {
      try {
        mensajeExitoCargando();
        const datos = {
          password: passwordRecuperar,
          repetir_password: confirmPasswordRecuperar,
        };
        await axios.post(
          `${process.env.REACT_APP_API_URL}/restablecer-password/${usuarioId}/${usuarioToken}`,
          datos
        );
        mensajeExitoRedirect()
      } catch (error) {
        if (error.status === 400) {
          mensajeErrorPersonalizado("Error en los campos de password");
        } else if (error.status === 401) {
          mensajeErrorPersonalizado(
            "El token ya expiro, debe enviar otra solicitud"
          );
        } else if (error.status === 403) {
          mensajeErrorPersonalizado("Error al validar el usuario");
        } else if (error.status === 404) {
          mensajeErrorPersonalizado("No se econtro el usuario");
        } else {
          mensajeErrorPersonalizado("Error en la solicitud");
        }
        setValido(false);
      }
    };
    solicitudCambiarPasswword();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordRecuperar !== confirmPasswordRecuperar) {
      setError("Las contraseñas no coinciden.");
      return;
    }
    setError("");
    setConfirmPasswordRecuperar("")
    setPasswordRecuperar("")
    cambiarPassword()
  };

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <div>
      {valido ? (
        <div className="container d-flex justify-content-center align-items-center vh-100">
          <div className="w-100" style={{ maxWidth: "400px" }}>
            <form
              onSubmit={handleSubmit}
              className="p-4 border rounded shadow-sm bg-white"
            >
              <h2 className="mb-4">Cambiar Contraseña</h2>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Nueva Contraseña
                </label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={passwordRecuperar}
                  onChange={(e) => setPasswordRecuperar(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Repetir Contraseña
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  className="form-control"
                  value={confirmPasswordRecuperar}
                  onChange={(e) => setConfirmPasswordRecuperar(e.target.value)}
                  required
                />
              </div>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <button type="submit" className="btn btn-primary w-100">
                Cambiar Contraseña
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="container d-flex justify-content-center align-items-center vh-100">
          <div className="w-100" style={{ maxWidth: "400px" }}>
            <div className="alert alert-danger" role="alert">
              No se pudo realizar la solicitud. Puedes ir a la página principal
              utilizando el botón de abajo.
            </div>
            <button className="btn btn-primary mt-3" onClick={handleRedirect}>
              Ir a la página principal
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecuperarPassword;
