import React from "react";
import { AuthProvider } from "../../../AuthContext.js";
import PuntoVentaPartner from "../puntoVenta/PuntoVentaPartner.js";

const AppPartner = () => {
  return (
    <AuthProvider>
      <div className="container">
        <PuntoVentaPartner/>
      </div>
    </AuthProvider>
  );
};

export default AppPartner;