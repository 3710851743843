import { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import CargaMedia from "../../../componentes/cargando/CargandoMedio";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { buscarServiciosService, crearServiciosService, editarServiciosService } from "../../../service/dueno/crudServicios";

const ConfiguracionesServicio = () => {
  const { verificacionToken } = useAuth();
  const [cargando, setCargando] = useState(true);
  const [servicios, setServicios] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [editingServicio, setEditingServicio] = useState(false);
  const [selectedServicioInfo, setSelectedServicioInfo] = useState([]);
  const [newServicio, setNewServicio] = useState({
    servicio: "",
    comision: "",
  });

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    buscarServicios()
  }, []);

  const buscarServicios = async () => {
    const respuesta = await buscarServiciosService()
    if(respuesta[0] === 200){
        setServicios(respuesta[1])
    }else{
        setServicios([])
    }
    setCargando(false)
  }

  const handleShowModal = () => {
    setEditingServicio(false);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setNewServicio({
      servicio: "",
      comision: "",
    });
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewServicio({
      ...newServicio,
      [name]: value,
    });
  };

  //agregar servicio
  const handleAddServicio = () => {
    const add = async () => {
      const respuesta = await crearServiciosService(newServicio);
      if (respuesta === 201) {
        buscarServicios();
      }
    };
    add();
    setNewServicio({
      id: "",
      nombres: "",
      direccion: "",
    });
    setEditingServicio(false);
    handleCloseModal();
  };

  const handleEditServicio = (servicio) => {
    setNewServicio({
      id: servicio.id,
      servicio: servicio.servicio,
      comision: servicio.comision,
    });
    setEditingServicio(true);
    setShowModal(true);
  };

   //editar servicio
   const handleUpdateServicio = () => {
    const update = async () => {
      const respuesta = await editarServiciosService(newServicio);
      if (respuesta === 201) {
        buscarServicios();
      }
    };
    update();
    setNewServicio({
      servicio: "",
      comision: "",
    });
    setEditingServicio(false);
    handleCloseModal();
  };

  const handleShowModalInfo = (servicio) => {
    setSelectedServicioInfo(servicio);
    setShowModalInfo(true);
  };

  const handleCloseModalInfo = () => {
    setShowModalInfo(false);
    setSelectedServicioInfo([]);
  };

  return (
    <div className="container border border-primary p-3 rounded-5">
        {cargando ? (
          <CargaMedia />
        ) : (
          <div>
            <div className="row align-items-center">
              <div className="col-md-9">
                <h2 className="display-3 text-primary shadow-title">Servicios</h2>
              </div>
              <div className="col-md-3 text-end">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleShowModal}
                >
                  Agregar Servicio
                </Button>
              </div>
            </div>
            {servicios.length === 0 ? (
              <div class="alert alert-danger" role="alert">
                No hay registro de servicio
              </div>
            ) : (
              <Table striped bordered hover responsive className="mt-3">
                <thead>
                  <tr>
                    <th>Servicio</th>
                    <th>Comisión</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {servicios.map((ser) => (
                    <tr key={ser.id}>
                      <td>{ser.servicio}</td>
                      <td>{ser.comision}%</td>
                      <td>
                        <div className="container">
                          <div className="row gap-2">
                            <Button
                              className="col flex-auto"
                              variant="btn btn-outline-warning"
                              onClick={() => handleEditServicio(ser)}
                            >
                              Editar
                            </Button>
                            <Button
                              className="col flex-auto"
                              variant="btn btn-outline-info"
                              onClick={() =>
                                handleShowModalInfo(ser.usuarios)
                              }
                            >
                              Información
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {editingServicio ? "Editar Servicio" : "Agregar Servicio"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formServicio">
                    <Form.Label>Servicio</Form.Label>
                    <Form.Control
                      type="text"
                      name="servicio"
                      placeholder="Nombre del Servicio"
                      value={newServicio.servicio}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formComision" className="mt-3">
                    <Form.Label>Comisión</Form.Label>
                    <Form.Control
                      type="text"
                      name="comision"
                      value={newServicio.comision}
                      onChange={handleInputChange}
                      placeholder="Establecer Comisión"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="btn btn-outline-danger"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </Button>
                <Button
                  variant="btn btn-outline-primary"
                  onClick={editingServicio ? handleUpdateServicio : handleAddServicio}
                >
                  {editingServicio ? "Actualizar" : "Agregar"}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModalInfo} onHide={handleCloseModalInfo} size="lg">
              <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                {selectedServicioInfo.length === 0 ? (
                  <div class="alert alert-danger text-center" role="alert">
                    <p>No hay empleados para este servicio.</p>
                  </div>
                ) : (
                  <div>
                    <h4>Listado del Personal</h4>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Rut</th>
                          <th>Nombre</th>
                          <th>Número</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedServicioInfo.map((empleado) => (
                          <tr key={empleado.id}>
                            <td>{empleado.perfil.rut}</td>
                            <td>
                              {empleado.perfil.nombres}{" "}
                              {empleado.perfil.apellidos}
                            </td>
                            <td>{empleado.perfil.numero}</td>
                            <td
                              style={{
                                color: empleado.enabled ? "green" : "red",
                              }}
                            >
                              {empleado.enabled ? "Habilitado" : "Bloqueado"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="btn btn-outline-secondary"
                  onClick={handleCloseModalInfo}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
  );
};

export default ConfiguracionesServicio;
