import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    // Redirige a la página principal
    navigate('/');
  };

  return (
    <div className="error404-container">
      <div className="error404-content">
        <h1>Error 404</h1>
        <p>La página que estás buscando no existe.</p>
        <button onClick={handleRedirect} className="btn btn-primary">
          Volver a la Página Principal
        </button>
      </div>
    </div>
  );
};

export default Error404;