import { useEffect, useState } from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { buscarMedioPagoService } from "../../../service/dueno/configuracionesServiceDueno";
import { useAuth } from "../../../AuthContext";
import CargaMedia from "../../../componentes/cargando/CargandoMedio";

const ConfiguracionesMedioPago = () => {
  const { verificacionToken } = useAuth();

  const [cargando, setCargando] = useState(true);
  const [metodosPagos, setMetodosPagos] = useState([]);

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    buscarMedioPago();
  }, []);

  const buscarMedioPago = async () => {
    const respuesta = await buscarMedioPagoService();
    if (respuesta[1] === 200) {
      setMetodosPagos(respuesta[0]);
    }
    setCargando(false);
  };

  return (
    <div className="container border border-primary p-3 rounded-5">
      {cargando ? (
        <CargaMedia />
      ) : (
        <div>
          <div className="row align-items-center">
            <div className="col-md-9">
              <h2 className="display-5 text-primary shadow-title">
                Métodos de Pagos
              </h2>
              <div className="col-md-4 text-end">
                <Button variant="btn btn-outline-primary">
                  Agregar Medio Pago
                </Button>
              </div>
            </div>
          </div>
          <Table striped bordered hover responsive className="mt-3">
            <thead>
              <tr>
                <th>Método de Pago</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {metodosPagos.map((pago) => (
                <tr key={pago.id}>
                  <td>{pago.metodoPago}</td>
                  <td
                    style={{
                      color: pago.enabled ? "green" : "red",
                    }}
                  >
                    {pago.enabled ? "Habilitado" : "Bloqueado"}
                  </td>
                  <td>
                        <div className="container">
                          <div className="row gap-2">
                            <Button
                              className="col flex-auto"
                              variant="btn btn-outline-warning"
                            >
                              Editar
                            </Button>
                            <Button
                              className="col flex-auto"
                              variant={pago.enabled?'btn btn-outline-danger':'btn btn-outline-success'}
                            >
                              {pago.enabled?'Bloquear':'Habilitar'}
                            </Button>
                          </div>
                        </div>
                      </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

    </div>
  );
};

export default ConfiguracionesMedioPago;
