import axios from "axios";
import { mensajeExitoCargando, mesanjeExitoPersonalizado } from "../../helpers/mensajesSwalExito";
import { mensajeErrorPersonalizado } from "../../helpers/mensajesSwalError";

const buscarServiciosService = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/servicios`,
      config
    );

    return [response.status, response.data]
  } catch (error) {
    return [error.status, error.response.data]
  }
};

const crearServiciosService = async (data) => {
    mensajeExitoCargando()
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/dueno/servicios`,
        data,
        config
      );
      mesanjeExitoPersonalizado(response.data)
      return response.status
    } catch (error) {
      mensajeErrorPersonalizado(error.response.data)
      return error.status
    }
  };

  const editarServiciosService = async (ser) => {
    mensajeExitoCargando()
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      }
    };

    const data = {
        servicio: ser.servicio,
        comision: ser.comision
    }
  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/dueno/servicios/${ser.id}`,
        data,
        config
      );
      mesanjeExitoPersonalizado(response.data)
      return response.status
    } catch (error) {
      mensajeErrorPersonalizado(error.response.data)
      return error.status
    }
  };

export { buscarServiciosService, crearServiciosService, editarServiciosService };
