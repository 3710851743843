import axios from "axios";

const buscarMetodopago = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };
    try {
      const respuestaPago = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrador/metodo-pago`,
        config
      );
      return [true, respuestaPago.data]
    } catch (error) {
      return [false, error.status]
    }
}

export{
    buscarMetodopago
}