import React from "react";
import { AuthProvider } from "../../../AuthContext.js";
import RutasDueno from "../rutas/RutasDueno.js";
import Header from "../../../componentes/layouts/Headers.js";

const AppDueno = () => {
  return (
    <AuthProvider>
      <Header/>
      <div style={{ paddingTop: "6rem" }} className="container">
        <RutasDueno />
      </div>
    </AuthProvider>
  );
};

export default AppDueno;
