import axios from "axios";

const buscarEmpleados = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };
    try {
      const respuesta = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrador/empleados`,
        config
      );
      return [true, respuesta.data]
    } catch (error) {
      return [false, error.status]
    }
}

export{
    buscarEmpleados
}