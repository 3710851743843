import React from "react";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../../AuthContext.js";
import { useNavigate } from "react-router-dom";
import { obtenerLogin, recuperarCuenta } from "../../service/autenticacion/login.js";
import { mesanjeExito } from "../../helpers/mensajesSwalExito.js";
import Carga from '../cargando/Cargando.js'

const Login = () => {
  const { login } = useAuth();
  const [activeView, setActiveView] = useState("login");
  const [email, setEmail] = useState("");
  const [emailRecuperar, setEmailRecuperar] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const getLogin = async (email, password) => {
      const loginAuth = await obtenerLogin(email, password);
      if (loginAuth[0]) {
        const closeButton = document.getElementById("closeModalButton");
        if (closeButton) {
          closeButton.click();
        }
        login({
          token: loginAuth[3],
          usuario_id: loginAuth[1],
          rol: loginAuth[2],
        });

        mesanjeExito()

        if (loginAuth[2] === "ROLE_DUENO") {
          navigate("/dueno");
        } else if (loginAuth[2] === "ROLE_ADMINISTRADOR") {
          navigate("/administrador");
        } else if (loginAuth[2] === "ROLE_EMPLEADO") {
          navigate("/empleado");
        } else if (loginAuth[2] === "ROLE_CLIENTE") {
          navigate("/");
        } else {
          navigate("/no-encontrado");
        }
      }
    };
    getLogin(email, password)
  };

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    // Lógica para registrarse
    console.log("registro");
  };

  const handleRecoverySubmit = (e) => {
    e.preventDefault();
    recuperarCuenta(emailRecuperar)
  };

  return (
    <div
      className="modal fade"
      id="botonInicioSesion"
      tabIndex="-1"
      aria-labelledby="modalInisionSesion"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              id="closeModalButton"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container text-center">
              <h1>
                {activeView === "login"
                  ? "Iniciar Sesión"
                  : activeView === "register"
                  ? "Crear Cuenta"
                  : "Recuperar Contraseña"}
              </h1>
              <div className="py-3 my-3">
                {activeView === "login" && (
                  <form onSubmit={handleLoginSubmit}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="email"
                        className="col-sm-4 col-md-3 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-8 col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="ejemplo@correo.cl"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label
                        htmlFor="password"
                        className="col-sm-4 col-md-3 col-form-label"
                      >
                        Contraseña
                      </label>
                      <div className="col-sm-8 col-md-9">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-success w-100">
                      Iniciar Sesión
                    </button>
                    <p className="mt-3">
                      ¿No tienes cuenta?{" "}
                      <a href="#" onClick={() => setActiveView("register")}>
                        Regístrate aquí
                      </a>
                    </p>
                    <p>
                      ¿Olvidaste tu contraseña?{" "}
                      <a href="#" onClick={() => setActiveView("recovery")}>
                        Recupérala aquí
                      </a>
                    </p>
                  </form>
                )}
                {activeView === "register" && (
                  <form onSubmit={handleRegisterSubmit}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="email"
                        className="col-sm-4 col-md-3 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-8 col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="ejemplo@correo.cl"
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label
                        htmlFor="password"
                        className="col-sm-4 col-md-3 col-form-label"
                      >
                        Contraseña
                      </label>
                      <div className="col-sm-8 col-md-9">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label
                        htmlFor="confirmPassword"
                        className="col-sm-4 col-md-3 col-form-label"
                      >
                        Confirmar Contraseña
                      </label>
                      <div className="col-sm-8 col-md-9">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          required
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                      Registrarse
                    </button>
                    <p className="mt-3">
                      ¿Ya tienes cuenta?{" "}
                      <a href="#" onClick={() => setActiveView("login")}>
                        Inicia sesión aquí
                      </a>
                    </p>
                  </form>
                )}
                {activeView === "recovery" && (
                  <form onSubmit={handleRecoverySubmit}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="recoveryEmail"
                        className="col-sm-4 col-md-3 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-8 col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          id="recoveryEmail"
                          placeholder="ejemplo@correo.cl"
                          value={emailRecuperar}
                          onChange={(e) => setEmailRecuperar(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-warning w-100">
                      Enviar Correo
                    </button>
                    <p className="mt-3">
                      ¿Recordaste tu contraseña?{" "}
                      <a href="#" onClick={() => setActiveView("login")}>
                        Inicia sesión aquí
                      </a>
                    </p>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
