import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Carga from "../../../componentes/cargando/Cargando.js";

const DashBoardDueno = () => {
  const navigate = useNavigate();
  const { verificacionToken } = useAuth();
  const [cargando, setCargando] = useState(true);
  const [ botonBloqueado, setBoronBloqueado ] = useState(true)

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    setCargando(false);
  }, []);

  if (cargando) {
    return <Carga />;
  }

  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-12 col-md-3">
          <div
            className="btn-group-vertical sticky-top"
            role="group"
            aria-label="Vertical button group"
          >
            <Link
              to="/dueno/empleados"
              className="btn btn-outline-success w-100 mb-2"
            >
              Registro de Empleados
            </Link>
            <Link
              to="/dueno/administrador"
              className="btn btn-outline-success w-100 mb-2"
            >
              Registro de Administrador
            </Link>
            <Link
              to="/dueno/ventas-empleados"
              className="btn btn-outline-success w-100 mb-2"
            >
              Ver Ventas Diarias
            </Link>
            <Link
              to="/dueno/ventasLocales"
              className={botonBloqueado?'btn btn-outline-success w-100 mb-2 disabled':'btn btn-outline-success w-100 mb-2'}
            >
              Ver Ventas Locales
            </Link>
            <Link
              to="/dueno/configuraciones"
              className="btn btn-outline-success w-100 mb-2"
            >
              Configuraciones
            </Link>
            <button
              type="button"
              className="btn btn-outline-success w-100 mb-2"
              onClick={() => navigate("/dueno")}
            >
              Volver Principal
            </button>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashBoardDueno;
