const formatoFecha = (fechaString) => {
  const fecha = new Date(fechaString);
  return fecha.toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const formatoHora = (fechaString) => {
  const fecha = new Date(fechaString);
  return fecha.toLocaleTimeString("es-ES", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const formatoMonedas = (precio) => {
  const precioFormateadoCLP = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  }).format(precio);
  return precioFormateadoCLP;
};

const formatoFechaBD = (fecha) => {
  const año = fecha.getFullYear();
  const mes = String(fecha.getMonth() + 1).padStart(2, '0');
  const dia = String(fecha.getDate()).padStart(2, '0');

  return `${año}-${mes}-${dia}`
}

const formatearAPesos = (valor) => {
  if (valor === 0 || valor === '') {
    return "$0";
  }

  const numero = parseInt(valor, 10);
  
  return !isNaN(numero) 
    ? new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(numero) 
    : '';
}

export { formatoFecha, formatoHora, formatoMonedas, formatoFechaBD, formatearAPesos };
