import { useEffect, useState } from "react";
import Carga from "../../../componentes/cargando/Cargando.js";
import {
  buscarVentasPorDuenoNuevo,
  buscarAniosRealizados,
  buscarLocalesDueno,
} from "../../../service/dueno/buscarVentas.js";
import { useAuth } from "../../../AuthContext";
import {
  mensajeError,
  mensajeErrorBloqueo,
  mensajeErrorNoAutorizado,
  mensajeErrorPersonalizado,
} from "../../../helpers/mensajesSwalError.js";
import { formatearAPesos } from "../../../helpers/formatos.js";
import { useNavigate } from "react-router-dom";
import CargandoMedio from "../../../componentes/cargando/CargandoMedio.js";

const obtenerMesActual = () => {
  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth() + 1;
  return mesActual;
};

const obtenerAnioActual = () => {
  return new Date().getFullYear();
};

const obtenerDiasDelMes = (m, a) => {
  return new Date(a, m, 0).getDate();
};

const generarListaDias = (mes, anio) => {
  const cantidadDias = obtenerDiasDelMes(mes, anio);
  return Array.from({ length: cantidadDias }, (_, i) => i + 1);
};

const obtenerDiaSemana = (dia) => {
  const fecha = new Date(new Date().getFullYear(), new Date().getMonth(), dia);
  const diasDeLaSemana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  return diasDeLaSemana[fecha.getDay()];
};

const RegistrosVentas = () => {
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(true);
  const { verificacionToken } = useAuth();
  const [mes, setMes] = useState(obtenerMesActual());
  const [anio, setAnio] = useState(obtenerAnioActual());
  const [anion, setAnion] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [locales, setLocales] = useState([]);
  const [cantidadDia, setCantidadDia] = useState(
    generarListaDias(obtenerMesActual(), obtenerAnioActual())
  );
  const [cargandoVentas, setCargandoVentas] = useState(true);
  const [cargandoLocales, setCargandoLocales] = useState(true);

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    buscarVentasPorAnio();
    buscarVentasPorMes(obtenerMesActual(), obtenerAnioActual());
    buscarLocales();
    setCargando(false);
  }, []);

  const buscarLocales = async () => {
    const respuesta = await buscarLocalesDueno();
    if (respuesta[0]) {
      setLocales(respuesta[1]);
    } else {
      if (respuesta[1] === 400) {
        mensajeErrorPersonalizado([2]);
      } else if (respuesta[1] === 401) {
        mensajeErrorNoAutorizado();
      } else if (respuesta[1] === 403) {
        mensajeErrorBloqueo();
      } else {
        mensajeError();
      }
    }
    setCargandoLocales(false);
  };

  const buscarVentasPorAnio = async () => {
    const respuesta = await buscarAniosRealizados();
    if (respuesta[0]) {
      setAnion(respuesta[1]);
    } else {
      if (respuesta[1] === 400) {
        mensajeErrorPersonalizado([2]);
      } else if (respuesta[1] === 401) {
        mensajeErrorNoAutorizado();
      } else if (respuesta[1] === 403) {
        mensajeErrorBloqueo();
      } else {
        mensajeError();
      }
    }
  };

  const buscarVentasPorMes = async (mes, anio) => {
    const respuesta = await buscarVentasPorDuenoNuevo(mes, anio);
    if (respuesta[0]) {
      setVentas(respuesta[1]);
    } else {
      if (respuesta[1] === 400) {
        mensajeErrorPersonalizado([2]);
      } else if (respuesta[1] === 401) {
        mensajeErrorNoAutorizado();
      } else if (respuesta[1] === 403) {
        mensajeErrorBloqueo();
      } else {
        mensajeError();
      }
    }
    setCargandoVentas(false);
  };

  const filtrarMes = (e) => {
    e.preventDefault();
    setCargando(true);
    buscarVentasPorMes(mes, anio);
    setCantidadDia(generarListaDias(mes, anio));
    setCargando(false);
  };

  const verVentaDiaria = (id) => {
    navigate(`/dueno/ventas-empleados/${id}`);
  };

  const addVentas = (d, m, a, id) => {
    navigate(`/dueno/ventas-empleados-crear-venta/${d}/${m}/${a}/${id}`);
  }

  return (
    <div>
      {cargando ? (
        <div>
          <Carga />
        </div>
      ) : (
        <div className="container mt-4">
          <h2 className="text-center">Ventas de Locales</h2>
          <div className="row mb-3 justify-content-center py-5">
            <form onSubmit={filtrarMes} className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="filtroMes" className="form-label">
                  Seleccionar Mes
                </label>
                <select
                  id="filtroMes"
                  className="form-select"
                  value={mes}
                  onChange={(e) => setMes(e.target.value)}
                >
                  <option value="1">Enero</option>
                  <option value="2">Febrero</option>
                  <option value="3">Marzo</option>
                  <option value="4">Abril</option>
                  <option value="5">Mayo</option>
                  <option value="6">Junio</option>
                  <option value="7">Julio</option>
                  <option value="8">Agosto</option>
                  <option value="9">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="filtroAnio" className="form-label">
                  Seleccionar Año
                </label>
                <select
                  id="filtroAnio"
                  className="form-select"
                  value={anio}
                  onChange={(e) => setAnio(e.target.value)}
                >
                  {anion.map((a) => (
                    <option key={a.id} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 d-flex align-items-end">
                <button type="submit" className="btn btn-outline-success me-2">
                  Aplicar Filtros
                </button>
              </div>
            </form>
          </div>
          <div>
            {cargandoVentas ? (
              <CargandoMedio />
            ) : (
              <div>
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th>Día</th>
                      {locales.map((local) => (
                        <th key={local.id}>{local.nombres}</th>
                      ))}
                    </tr>
                  </thead>
                  {cargandoLocales ? (
                    <CargandoMedio />
                  ) : (
                    <tbody>
                      {cantidadDia.map((dia, index) => {
                        return (
                          <tr key={`dia-${index}`}>
                            <td>
                              {index + 1} {obtenerDiaSemana(dia)}
                            </td>

                            {locales.map((local) => {
                              const ventaDelDia = ventas.find(
                                (venta) =>
                                  venta.diaVenta === index + 1 &&
                                  venta.local_id === local.id
                              );

                              return (
                                <td key={`local-${local.id}`}>
                                  {ventaDelDia ? (
                                    <button
                                      className={
                                        Number(local.kpi) <= Number(ventaDelDia.venta_total)
                                          ? "btn btn-sm btn-outline-primary"
                                          : "btn btn-sm btn-outline-danger"
                                      }
                                      onClick={() =>
                                        verVentaDiaria(ventaDelDia.id)
                                      }
                                    >
                                      Monto del Día:{" "}
                                      {formatearAPesos(ventaDelDia.venta_total)}
                                    </button>
                                  ) : (
                                    <div>
                                      {new Date(
                                        anio,
                                        mes - 1,
                                        index + 1
                                      ).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? (
                                        <p className="text-danger">
                                          No hay venta
                                        </p>
                                      ) : (
                                        <button className="btn btn-sm btn-outline-secondary" 
                                        onClick={() => addVentas((index + 1), mes, anio, local.id)}>
                                          Crear Venta
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrosVentas;
