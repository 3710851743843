import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CargaMedia = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-50">
      <div className="text-center">
        <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
          <span className="visually-hidden">Cargando...</span>
        </div>
        <div className="mt-3">
          <h5>Cargando, por favor espere...</h5>
        </div>
      </div>
    </div>
  );
};

export default CargaMedia;