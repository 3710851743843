import React from "react";
import Login from "../layouts/Login.js";
import { useAuth } from '../../AuthContext.js';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Header = () => {

  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const redirigirDashBoard = () => {
    const getSession = async () => {
      if(isAuthenticated){
        const rol = localStorage.getItem('rol')
        const token = localStorage.getItem('token')
        const usuario_id = localStorage.getItem('usuario_id')
        const datos = {
          token,
          usuario_id,
        };
  
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/validar-sesion-rol`,
            datos
          )
      
          if(response.data === rol){
            if(response.status === 200){
              if(response.data === 'ROLE_DUENO'){
                navigate('/dueno')
              }else if(response.data === 'ROLE_ADMINISTRADOR'){
                navigate('/administrador')
              }else if(response.data === 'ROLE_EMPLEADO'){
                navigate('/empleado')
              }else{
                navigate('/')
              }
            }else{
              navigate('/no-encontrado')
            }
          }else{
            navigate('/no-encontrado')
          }
        } catch (error) {
          navigate('/no-encontrado')
        }
      }
    }
    getSession()
  }

  return (
    <header>
      <nav className="navbar navbar-expand-lg fixed-top bg-light navbar-light">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/static/img/logo.png"
              alt="logo de antonia depilacion"
              draggable="false"
              height="60"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto align-items-center">
            <li className="nav-item ms-3">
                {isAuthenticated ? (
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={redirigirDashBoard}
                  >
                    Dash Board
                  </button>
                ):(
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={redirigirDashBoard}
                  >
                    Pedir Mí Hora
                  </button>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2" href="#!">
                  <i className="fas fa-plus-circle pe-2"></i>Locales
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2" href="#!">
                  <i className="fas fa-bell pe-2"></i>Servicios
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2" href="#!">
                  <i className="fas fa-heart pe-2"></i>Agenda tu hora
                </a>
              </li>
              <li className="nav-item ms-3">
                {isAuthenticated ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={logout}
                  >
                    Salir
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#botonInicioSesion"
                  >
                    Inicio Sesión
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Login />
    </header>
  );
};

export default Header;
