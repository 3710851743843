import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error403 = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <div className="error403-container">
      <div className="error403-content">
        <h1>Error 403</h1>
        <p>No tienes permiso para acceder a esta página.</p>
        <button onClick={handleRedirect} className="btn btn-primary">
          Volver a la Página Principal
        </button>
      </div>
    </div>
  );
};

export default Error403;