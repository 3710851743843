import React from 'react';

const CargandoComponente = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary" role="status" style={{ width: '1.5rem', height: '1.5rem' }}>
        <span className="visually-hidden">Cargando...</span>
      </div>
    </div>
  );
};

export default CargandoComponente;