import axios from "axios";
import {
  mensajeExitoCargando,
  mesanjeExitoPersonalizado,
} from "../../helpers/mensajesSwalExito";
import { mensajeErrorPersonalizado } from "../../helpers/mensajesSwalError";

const buscarLocales = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    }
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-locales`,
      config
    );
    return [response.data, response.status];
  } catch (error) {
    return [error.data, error.status];
  }
};

const agregarLocales = async (local) => {
    mensajeExitoCargando();
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  const data = {
    nombres: local.nombres,
    direccion: local.direccion,
    kpi: local.kpi
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-locales`,
      data,
      config
    );
    mesanjeExitoPersonalizado(response.data);
    return [response.data, response.status];
  } catch (error) {
    mensajeErrorPersonalizado(error.response.data);
    return [error.data, error.status];
  }
};

const actualizarLocales = async (local) => {
  mensajeExitoCargando();
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
    params: {
      id: local.id,
    },
  };
  const data = {
    nombres: local.nombres,
    direccion: local.direccion,
    kpi: local.kpi
  };
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-locales`,
      data,
      config
    );
    mesanjeExitoPersonalizado(response.data);
    return [response.data, response.status];
  } catch (error) {
    mensajeErrorPersonalizado(error.response.data);
    return [error.data, error.status];
  }
};

const buscarMedioPagoService = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      }
    };

    try{
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/dueno/buscar-medio-pago`,
            config
          );
          return [response.data, response.status]
    }catch(error){
        return [error.data, error.status]
    }
}

export { buscarLocales, agregarLocales, actualizarLocales, buscarMedioPagoService };
